import React from "react";
import ComponentTooltip from "../../../../shared/components/component-tooltip/ComponentTooltip";

/**
 * Custom label - offer view
 * @param {*} param0
 */
const OfferLabel = ({
	label = "",
	className = "mb-regular",
	value = null,
	valueAppend = false,
	tooltipValue = null,
	showTooltip = false,
	tooltipPlacement = "top",
}) => {
	let tooltipContent = tooltipValue ? tooltipValue : value;

	return (
		<>
			{showTooltip ? (
				<ComponentTooltip content={tooltipContent} direction={tooltipPlacement}>
					<div className={`${className} text-overflow-ellipsis`}>
						{value}
						{valueAppend && valueAppend()}
					</div>
				</ComponentTooltip>
			) : (
				<div className={`body-font ${className}`}>
					{value}
					{valueAppend && valueAppend()}
				</div>
			)}

			<div className="color-n300">{label && label} </div>
		</>
	);
};

export default OfferLabel;
