import companyConstants from "../constants/companyConstants";
import { api } from "../api";
import { getGeocode } from "use-places-autocomplete";
import { destroy, reset } from "redux-form";
import history from "../history";
import localize, { LocKeys } from "../constants/localizations";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { companyTypesConstants, statusConstants } from "../constants/constants";
import { getCompanyFilters, getPagination } from "../utils/table-filter";
import { isBimifyReseller } from "../utils/permission/user";

/**
 * Retrieves all companies with pagination. It is used for listing in the table.
 *
 * SUCCESS: Populates 'companiesList' in the company state.
 *
 * @param {number} page - Page to retrieve
 * @param {string} searchQuery - Search string
 * @param {object} searchFilters - Search string
 * @param {Array} types - Company type
 * @param {Array} countries - Company country
 * @param {boolean} customer
 * @param {number} limit
 *
 * @returns http response with array of companies
 */
export const getCompanies = (
	page = 1,
	searchQuery = "",
	searchFilters = null,
	roles,
	countries,
	types,
	customer = getItemFromLocalStorage("Customer"),
	limit,
	resellerCompanyId = getItemFromLocalStorage("Reseller")
) => {
	return (dispatch, getState) => {
		const state = getState();

		const customerQueryParameter = state.getIn([
			"queryParameter",
			"customerQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { typesFilter, rolesFilter, countriesFilter, filters, search } =
			getCompanyFilters({
				queryParameter: customerQueryParameter,
				searchFilters,
				searchQuery,
				types,
				roles,
				countries,
			});

		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const requestOptions = {
			method: "PUT",
			url: `/company/get-all`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				page: currentPage,
				search,
				businessTypeIds: typesFilter,
				limit: currentLimit,
				typeIds: rolesFilter,
				billingCountryIds: countriesFilter,
				filters,
				selectedCompany: customer,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({ type: companyConstants.GET_COMPANIES, data: res.data });
				dispatch(
					getCompanyStats({
						customer,
						search,
						billingCountryIds: countriesFilter,
						typeIds: rolesFilter,
						businessTypeIds: typesFilter,
						filters,
						resellerCompanyId,
					})
				);
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves company stats
 *
 * SUCCESS: Populates 'stats' in the company state.
 *
 * @returns response with array of company stats
 */
export const getCompanyStats = ({
	customer = getItemFromLocalStorage("Customer"),
	search,
	billingCountryIds,
	typeIds,
	businessTypeIds,
	filters,
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/company-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				customer,
				search,
				billingCountryIds,
				typeIds,
				businessTypeIds,
				filters,
				resellerCompanyId,
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({ type: companyConstants.COMPANY_STATS, data });
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

export const getGlobalCompanies = ({
	type = null,
	resellerCompanyId = null,
}) => {
	return (dispatch) => {
		const typeQuery = type ? `type=${type}` : "";
		const resellerQuery = resellerCompanyId
			? `&resellerCompanyId=${resellerCompanyId}`
			: "";

		const requestOptions = {
			method: "GET",
			url: `/company?${typeQuery}${resellerQuery}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (type === companyTypesConstants.RESELLER) {
					dispatch({
						type: companyConstants.GET_GLOBAL_RESELLER_COMPANIES,
						data: res.data?.result,
					});
				} else {
					dispatch({
						type: companyConstants.GET_GLOBAL_CLIENT_COMPANIES,
						data: res.data?.result,
					});
				}
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get bimify company
 *
 * SUCCESS: Populates 'bimifyCompany' in the company state.
 *
 * @returns
 */
export const getBimifyCompany = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: "/company/bimify",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({ type: companyConstants.GET_BIMIFY_COMPANY, data: res.data });
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves single company based on its ID.
 *
 * SUCCESS: Populates 'company' in the company state.
 *
 * @param {number} id - Unique company identifier
 * @param {boolean} isSupplier - true/false, true- Populates 'supplier' in the company state
 * @param {*} cb - callback function
 */
export const getCompany = (id, isSupplier = false, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/company/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (isSupplier) {
					dispatch({
						type: companyConstants.GET_SUPPLIER,
						data: res.data.result,
					});
				} else {
					dispatch({
						type: companyConstants.GET_COMPANY,
						data: res.data.result,
					});
					dispatch({
						type: companyConstants.SET_TEMP_COMPANY,
						data: res.data.result,
					});
					try {
						res.data.result?.address?.formattedAddress &&
							getGeocode({
								address: res.data.result.address.formattedAddress,
							}).then((results) => {
								dispatch(setCompanyAddress(JSON.stringify(results)));
							});
					} catch (error) {
						console.log(error);
					}
				}
			},
			(err) => {
				cb();
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Retrieves a list of all countries where at least a single company (regardless of type)
 * is located at.
 *
 * SUCCESS: Populates the 'companyCountries' in the company state.
 */
export const getCompanyCountries = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/company/countries`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: companyConstants.GET_COMPANY_COUNTRIES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Method used to add new company.
 *
 * SUCCESS: Updates 'companiesList' in the company state.
 *
 *  @param {object} formValues - Contains all company form data in a key-value pair
 */
export const createCompany = (
	formValues,
	cb = () => {},
	cbError = () => {}
) => {
	return (dispatch) => {
		let bodyFormData = new FormData();
		Object.keys(formValues).forEach((key) =>
			bodyFormData.append(key, formValues[key])
		);
		const requestOptions = {
			method: "POST",
			url: `/company`,
			headers: {
				"Content-Type":
					"multipart/form-data; boundary=<calculated when request is sent>",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: bodyFormData,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					setCompanySuccessMessage(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.COMPANY)])
					)
				);
				dispatch(actionCompanySuccess(res.data.result));

				cb(res.data.result);
				dispatch(reFetchGlobalCompanies());
				//	dispatch(clearTempCompany());
			},
			(err) => {
				const result = err.response.data.result;

				if (result?.field) {
					switch (result.field) {
						case "email":
						case "registrationNumber":
						case "name":
						case "phone":
							cbError(result);
							break;
						default:
							history.goBack();
							dispatch(
								actionCompanyFailure(
									localize(LocKeys.ERROR_ITEM_CREATED, [
										localize(LocKeys.COMPANY),
									])
								)
							);
							break;
					}
				} else {
					cb();
					dispatch(
						actionCompanyFailure(
							localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.COMPANY)])
						)
					);
				}
			}
		);
	};
};

/**
 * Update company endpoint. Used to change the data of a single company.
 *
 * SUCCESS: Depending on singleView value, either 'company' in the company state will be updated; or,
 * 'companiesList' will be.
 *
 * @param {object} formValues - Contains all company form data in a key-value pair
 * @param {number} id - Unique company identifier
 */
export const updateCompany = (
	formValues,
	id,
	cb = () => {},
	cbError = () => {}
) => {
	return (dispatch) => {
		let bodyFormData = new FormData();
		Object.keys(formValues).forEach((key) =>
			bodyFormData.append(key, formValues[key])
		);

		const requestOptions = {
			method: "PUT",
			url: `/company/${id}`,
			headers: {
				"Content-Type":
					"multipart/form-data; boundary=<calculated when request is sent>",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: bodyFormData,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					setCompanySuccessMessage(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.COMPANY)])
					)
				);
				dispatch(actionCompanySuccess(res.data.result));
				dispatch(reFetchGlobalCompanies());
				cb();
			},
			(err) => {
				const result = err.response.data.result;
				if (result?.field) {
					switch (result.field) {
						case "email":
						case "taxId":
						case "name":
						case "contactPerson":
							cbError(result);
							break;

						default:
							history.goBack();
							dispatch(
								actionCompanyFailure(
									localize(LocKeys.ERROR_ITEM_UPDATED, [
										localize(LocKeys.COMPANY),
									])
								)
							);
							break;
					}
				} else {
					history.goBack();
					dispatch(
						actionCompanyFailure(
							localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.COMPANY)])
						)
					);
				}
			}
		);
	};
};

/**
 * Archive or activate company - change company status and send to endpoint
 * Depending on the type, this endpoint either archives or activates a company.
 *
 * SUCCESS: Updates 'companiesList' in the company state.
 *
 * @param {*} isActive
 * @param {*} ids - Unique company identifiers
 */
export const toggleCompanyStatus = (isActive, ids) => {
	let urlPart = "archive";
	let responseMessage = localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
		localize(LocKeys.COMPANY),
	]);

	if (!isActive) {
		urlPart = "activate";
		responseMessage = localize(LocKeys.ITEM_ACTIVE_SUCCESS, [
			localize(LocKeys.COMPANY),
		]);
	}
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/company-${urlPart}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getCompanies());
				dispatch(actionCompanySuccess(res.data.result));

				dispatch(setCompanySuccessMessage(responseMessage));
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response));
			}
		);
	};
};

/**
 * Soft DELETE company
 *
 * @param {*} ids - Unique company identifiers
 *
 * SUCCESS: Updates 'companiesList' in the company state.
 */
export const deleteCompany = (ids) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/company-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				const filters = {
					status: [statusConstants.ARCHIVED],
				};
				dispatch(getCompanies(1, "", filters));
				dispatch(actionCompanySuccess(res.data.result));
				dispatch(
					setCompanySuccessMessage(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.COMPANY)])
					)
				);
			},
			(err) => {
				dispatch(
					actionCompanyFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.COMPANY)])
					)
				);
			}
		);
	};
};

/**
 * Retrieves all admins of a specific company, based on its ID.
 *
 * SUCCESS: Populates 'admins' in the company state.
 *
 * @param {number} id - Unique company identifier
 * @param {boolean} isSupplier -true/false, true - Populates 'supplierAdmins' in the company state.
 */
export const getCompanyAdmins = (id, isSupplier) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/user/${id}/admins`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};

		return api(requestOptions).then(
			(res) => {
				if (isSupplier) {
					dispatch({
						type: companyConstants.GET_SUPPLIER_ADMINS,
						data: res.data.result,
					});
				} else {
					dispatch({
						type: companyConstants.GET_ADMINS,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

export const getCompanyAdminsAndBos = (
	id,
	isSupplier = false,
	isReseller = false
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/user/${id}/admins-bos`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};

		return api(requestOptions).then(
			(res) => {
				if (isSupplier) {
					dispatch({
						type: companyConstants.GET_SUPPLIER_ADMINS,
						data: res.data.result,
					});
				} else if (isReseller) {
					dispatch({
						type: companyConstants.GET_RESELLER_ADMINS,
						data: res.data.result,
					});
				} else {
					dispatch({
						type: companyConstants.GET_ADMINS,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

export const getCompanyPLs = (id, isSupplier = false, isReseller = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/user/${id}/project-leads`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};

		return api(requestOptions).then(
			(res) => {
				if (isSupplier) {
					dispatch({
						type: companyConstants.GET_SUPPLIER_PL,
						data: res.data.result,
					});
				} else if (isReseller) {
					dispatch({
						type: companyConstants.GET_RESELLER_PL,
						data: res.data.result,
					});
				} else {
					dispatch({
						type: companyConstants.GET_CLINET_PL,
						data: res.data.result,
					});
				}
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get company types
 * @returns
 */
export const getCompanyTypes = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: "/business-type",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: companyConstants.GET_COMPANY_TYPES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get company roles
 * @returns
 */
export const getCompanyRoles = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: "/company/company-types",
			headers: {
				"Content-Type": "application/json",
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: companyConstants.GET_COMPANY_ROLES,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(actionCompanyFailure(err.response.data.message));
			}
		);
	};
};

const reFetchGlobalCompanies = () => {
	return (dispatch) => {
		dispatch(
			getGlobalCompanies({
				type: companyTypesConstants.CLIENT,
				resellerCompanyId: getItemFromLocalStorage("Reseller"),
			})
		);

		if (isBimifyReseller()) {
			getGlobalCompanies({ type: companyTypesConstants.RESELLER });
		}
	};
};

//---------------------------------END ENDPOINTS----------------------------------------------

//---------------------------------COMPANY STATE FUNCTION-------------------------------------

export const setTempCompanyValue = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: companyConstants.SET_TEMP_COMPANY_VALUE,
			data: { fieldKey, value },
		});
	};
};

export const clearTempCompany = () => {
	return (dispatch) => {
		dispatch({ type: companyConstants.CLEAR_TEMP_COMPANY });
		dispatch(destroy("companyForm"));
	};
};

/**
 * CLEAR Company state
 *  1. Clear temp company state
 *  2. Clear company state
 * 	3. Reset 'companyForm'
 *
 */
export const clearCurrentCompany = () => {
	return (dispatch) => {
		dispatch(clearCompanyTemp());
		dispatch(clearCompany());
		dispatch(reset("companyForm"));
	};
};

export const setCompanySuccessMessage = (data) => {
	return { type: companyConstants.SET_SUCCESS_MESSAGE, data };
};

/**
 * SET company image
 */
export const setCompanyImage = (image) => {
	return { type: companyConstants.SET_TEMP_IMAGE, image };
};

/**
 * CLEAR company temp object
 */
export const clearCompanyTemp = () => {
	return { type: companyConstants.CLEAR_COMPANY_TEMP };
};
/**
 * CLEAR company object
 */
export const clearCompany = () => {
	return { type: companyConstants.CLEAR_COMPANY };
};

export const actionCompanySuccess = (company) => {
	return { type: companyConstants.C_ACTION_SUCCESS, company };
};

export const actionCompanyFailure = (error) => {
	return { type: companyConstants.C_ACTION_FAILURE, error };
};

/**
 * SET company address
 */
export const setCompanyAddress = (address) => {
	return { type: companyConstants.SET_FULL_ADDRESS, address };
};

export const setAddressFieldValue = (fieldValue) => {
	return { type: companyConstants.FIELD_VALUE, fieldValue };
};

export const companyRequestDone = () => {
	return { type: companyConstants.C_REQUEST_DONE };
};

/**
 * CLEAR company request state
 */
export const clearCompanyRequestState = () => {
	return { type: companyConstants.CLEAR_COMPANY_REQUEST_STATE };
};
