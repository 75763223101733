import { Map, fromJS } from "immutable";
import companyConstants from "../constants/companyConstants";
import { companyTypesConstants } from "../constants/constants";
/**
 * Company reducer redux
 */

const initialState = Map({
	action: false,
	request: false,
	company: Map(),
	fullAddress: null,
	admins: Map(),
	companiesList: Map(),
	globalResellerCompanies: Map(),
	globalClientCompanies: Map(),
	bimifyCompany: null,
	companiesMeta: Map(),
	companyCountries: Map(),
	errorResponse: "",
	fieldValue: "",
	tempCompany: Map({
		name: "",
		type: "",
		registrationNumber: "",
		role: "",
		whoHandleOrder: "",
		address1: "",
		address2: "",
		postalCode: "",
		city: "",
		country: "",
		billingEmail: "",
		firstName: "",
		lastName: "",
		phone: "",
		email: "",
		contactPerson: "",
	}),
	tempData: Map({
		image: null,
	}),
	stats: null,
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});
//

export default (state = initialState, action) => {
	const setTempCompanyValue = (data) => {
		const { fieldKey, value } = data;
		return state.setIn(["tempCompany", fieldKey], value);
	};

	const setTempCompany = (data) => {
		const body = {
			name: data.name,
			registrationNumber: data.registrationNumber,
			role: data.type?.id || "",
			type: data.businessTypeId,
			contactPerson: data.contactPerson?.id || "",
			reseller: data.resellerCompanyId || "",
			whoHandleOrder: data.isResellerHandlingOrders
				? companyTypesConstants.RESELLER
				: companyTypesConstants.CLIENT,
			address1: data.billingAddressOne,
			address2: data.billingAddressTwo,
			postalCode: data.billingPostalCode,
			city: data.billingCity,
			country: data.billingCountryId,
			billingEmail: data.billingEmail,
			discount: data.billingDiscount,
		};
		return state.set("tempCompany", fromJS(body));
	};

	switch (action.type) {
		case companyConstants.GET_COMPANIES:
			return state
				.set("companiesList", fromJS(action.data.result))
				.set("companiesMeta", fromJS(action.data.meta));

		case companyConstants.GET_BIMIFY_COMPANY:
			return state.set("bimifyCompany", fromJS(action.data.result));

		case companyConstants.GET_COMPANY_COUNTRIES:
			return state.set("companyCountries", fromJS(action.data));

		case companyConstants.GET_GLOBAL_RESELLER_COMPANIES:
			return state.set("globalResellerCompanies", fromJS(action.data));

		case companyConstants.GET_GLOBAL_CLIENT_COMPANIES:
			return state.set("globalClientCompanies", fromJS(action.data));

		case companyConstants.C_ACTION_SUCCESS:
			return state
				.set("action", true)
				.set("request", true)
				.set("company", fromJS(action.company));

		case companyConstants.GET_COMPANY:
			return state.set("company", fromJS(action.data));

		case companyConstants.GET_SUPPLIER:
			return state.set("supplier", fromJS(action.data));

		case companyConstants.C_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case companyConstants.SET_TEMP_IMAGE:
			return state.setIn(["tempData", "image"], action.image);

		case companyConstants.SET_FULL_ADDRESS:
			return state.set("fullAddress", fromJS(action.address));

		case companyConstants.FIELD_VALUE:
			return state.set("fieldValue", fromJS(action.fieldValue));

		case companyConstants.GET_ADMINS:
			return state.set("admins", fromJS(action.data));

		case companyConstants.GET_SUPPLIER_ADMINS:
			return state.set("supplierAdmins", fromJS(action.data));

		case companyConstants.GET_RESELLER_ADMINS:
			return state.set("resellerAdmins", fromJS(action.data));

		case companyConstants.GET_SUPPLIER_PL:
			return state.set("supplierPL", fromJS(action.data));

		case companyConstants.GET_RESELLER_PL:
			return state.set("resellerPL", fromJS(action.data));

		case companyConstants.GET_CLINET_PL:
			return state.set("clientPL", fromJS(action.data));

		case companyConstants.C_REQUEST_DONE:
			return state.set("request", false);

		case companyConstants.ACTION_GET_FAILURE:
			return state.set("errorResponse", fromJS(action.error));

		case companyConstants.SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case companyConstants.CLEAR_COMPANY:
			return state.set("company", Map());

		case companyConstants.CLEAR_COMPANY_TEMP:
			return state.set("tempData", {
				image: null,
			});

		case companyConstants.SET_TEMP_COMPANY_VALUE:
			return setTempCompanyValue(action.data);

		case companyConstants.SET_TEMP_COMPANY:
			return setTempCompany(action.data);

		case companyConstants.CLEAR_TEMP_COMPANY:
			return state.set("tempCompany", initialState.get("tempCompany"));

		case companyConstants.GET_COMPANY_TYPES:
			return state.set("companyTypes", fromJS(action.data));

		case companyConstants.GET_COMPANY_ROLES:
			return state.set("companyRoles", fromJS(action.data));

		case companyConstants.CLEAR_COMPANY_REQUEST_STATE:
			return state.set("action", false).set("request", false);

		case companyConstants.COMPANY_STATS:
			return state.set("stats", fromJS(action.data.result));

		default:
			return state;
	}
};
