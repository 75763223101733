import { Map, fromJS } from "immutable";
import orderTempConstants from "../constants/orderTempConstants";
import { getNewValueFromDiffPercentage } from "../utils/math-helper/MathHelper";

/**
 * Project reducer redux
 */

const initialState = Map({
	init: true,
	projectAction: false,
	projectRequest: false,
	project: Map({}),
	exports: [],
	buildings: [
		{
			validity: Map({
				inputFormat: false,
				size: false,
				//lodSpecification: false,
				type: false,
				levelsValidity: true,
			}),
		},
	],
	validity: Map({
		deliveryFormat: false,
		numberOfBuildings: false,
		name: false,
		specification: false,
		template: false,
	}),
	companyInfo: Map({
		clientId: null,
		clientCP: null,
		clientLocation: null,
		clientPL: null,
		name: null,
		supplierCP: null,
		supplierId: null,
		supplierPL: null,
		supplierLocation: null,
	}),
	stopPricing: false,
	pricePerSquarePercentage: null,
	stopCalculatePercentage: false,
	stopCalculateExportPercentage: false,
	termsAndConditions: null,
	specification: null,
	template: null,
	clientCompany: Number(),
	additionalInfo: null,
});

export default (state = initialState, action) => {
	/**
	 * Update number of buildings
	 * @param {*} value - number of buildings
	 * @returns
	 */
	const updateBuilding = (value) => {
		let tempBuildings = [];
		const buildings = state.get("buildings");

		for (let index = 1; index <= value; index++) {
			const find = buildings.find((item) => item.id === index);

			if (find) {
				tempBuildings.push(find);
			} else {
				tempBuildings.push({
					id: index,
					name: `Building ${index}`,
					isCustomName: false,
					size: null,
					price: null,
					pricePerSquare: null,
					inputFormat: "",
					type: "",
					lodSpecification: "",
					levels: null,
					uniqueLevels: null,
					validity: Map({
						size: false,
						inputFormat: false,
						type: false,
						levelsValidity: true,
					}),
				});
			}
		}

		return state.set("buildings", tempBuildings);
	};

	/**
	 * Update building data (buildings list)
	 * @param {*} data
	 * @returns
	 */
	const updateBuildingData = (data) => {
		const { stateKey, value, isValid, buildingId, isEdit = false } = data;
		let levelsValidity = true;

		const buildings = state.get("buildings");
		const index = buildings.findIndex((building) => building.id === buildingId);

		if (stateKey === "levels" && +value < +buildings[index].uniqueLevels) {
			levelsValidity = false;
		} else if (
			stateKey === "uniqueLevels" &&
			+value > +buildings[index].levels
		) {
			levelsValidity = false;
		}

		let stopPricing = false;
		if (
			stateKey === "price" ||
			stateKey === "name" ||
			stateKey === "isCustomName"
		)
			stopPricing = true;

		return state
			.set("stopPricing", stopPricing)
			.set("tempBuildingId", buildingId)
			.setIn(
				["buildings", index, "price"],
				stopPricing || isEdit ? buildings[index].price : null
			)
			.setIn(
				["buildings", index, "pricePerSquare"],
				stopPricing || isEdit ? buildings[index].pricePerSquare : null
			)
			.setIn(["buildings", index, stateKey], value)
			.setIn(["buildings", index, "validity", stateKey], isValid)
			.setIn(["buildings", index, "validity", "levelsValidity"], levelsValidity)
			.set("stopCalculatePercentage", false);
	};

	/**
	 * Remove building form list
	 * @param {*} data
	 * @returns
	 */
	const removeBuilding = (data) => {
		const { buildingId } = data;
		const buildings = state.get("buildings");

		let tempBuildings = buildings.filter((item) => +item.id !== +buildingId);

		for (let index = 1; index <= tempBuildings.length; index++) {
			tempBuildings[index - 1].id = index;
			tempBuildings[index - 1].name = tempBuildings[index - 1].isCustomName
				? tempBuildings[index - 1].name
				: `Building ${index}`;
		}

		return state
			.set("buildings", tempBuildings)
			.set("tempBuildingId", null)
			.set("stopPricing", false);
	};

	/**
	 * Set export version - TEMP Offer
	 * @param {*} data
	 * @returns
	 */
	const setExports = (data) => {
		const { id, exportId, isSelected, price = 0, fieldKey = null } = data;

		const exports = state.get("exports");
		const findIndex = exports.findIndex((item) => item.id === id);

		let stopPricing = false;
		if (fieldKey === "price") stopPricing = true;

		if (findIndex !== -1) {
			if (+price >= 0 && !exportId) {
				return state
					.set("stopPricing", stopPricing)
					.setIn(["exports", findIndex, "customPrice"], price)
					.set("stopCalculateExportPercentage", false);
			}
			if (!isSelected) {
				const tempFilterExports = exports.filter((item) => +item.id !== +id);

				return state
					.set("stopPricing", stopPricing)
					.set("exports", tempFilterExports);
			} else {
				return state
					.set("stopPricing", stopPricing)
					.setIn(["exports", findIndex, "exportId"], exportId)
					.setIn(["exports", findIndex, "valid"], exportId ? true : false);
			}
		}

		let tempExports = [
			{
				id: id,
				exportId: exportId,
				price: null,
				customPrice: null,
				valid: exportId ? true : false,
			},
		];
		if (exports.length > 0) {
			tempExports = tempExports.concat(exports);
		}

		return state.set("stopPricing", stopPricing).set("exports", tempExports);
	};

	/**
	 * Update price on the exports and buildings object
	 * @param {*} data - { buildingPrice, exportsPrice }
	 * @returns
	 */
	const updatePrice = (data) => {
		const { buildingPrice, exportsPrice } = data;

		let tempBuildings = [];
		let tempExports = [];
		const exports = state.get("exports");
		const buildings = state.get("buildings");

		buildings &&
			buildings.forEach((building) => {
				building.price =
					buildingPrice.pricePerBuildingDetailed[building.id]?.buildingPrice;
				building.pricePerSquare =
					buildingPrice.pricePerBuildingDetailed[building.id]?.pricePerSquare;
				building.customPricePerSquare =
					buildingPrice.pricePerBuildingDetailed[building.id]?.pricePerSquare;

				building.customComplexityAdjustment = building.complexityAdjustment;

				building.customAdjustedPricePerSquare = Number(
					buildingPrice.pricePerBuildingDetailed[building.id]
						?.pricePerSquareAdjusted
				)?.toFixed(3);

				tempBuildings.push(building);
			});

		exports &&
			exports.forEach((item) => {
				item.customPrice = exportsPrice?.pricePerExport[item.exportId];
				item.price = exportsPrice?.pricePerExport[item.exportId];
				tempExports.push(item);
			});

		return state
			.set("buildings", tempBuildings)
			.set("exports", tempExports)
			.set("stopCalculatePercentage", false)
			.set("stopCalculateExportPercentage", false)
			.set("pricePerSquarePercentage", null);
	};

	const updateBuildingPrice = (data) => {
		const { buildingPrice } = data;

		let tempBuildings = [];

		const buildings = state.get("buildings");

		buildings &&
			buildings.forEach((building) => {
				building.price =
					buildingPrice.pricePerBuildingDetailed[building.id]?.buildingPrice ||
					null;
				building.pricePerSquare =
					buildingPrice.pricePerBuildingDetailed[building.id]?.pricePerSquare ||
					null;

				tempBuildings.push(building);
			});

		return state.set("buildings", tempBuildings).set("stopPricing", true);
	};

	/**
	 * Set temp offer state from single package - GET package endpoint
	 * @param {object} packageData - {packageExports, lodSpecification}
	 * @returns state
	 */
	const setStateFromPackage = (packageData) => {
		let orderExports = [];
		const { packageExports, lodSpecification } = packageData;

		//Offer exports
		packageExports &&
			packageExports.map((item) => {
				const exportChildren = item?.export?.children;
				return orderExports.push({
					exportId:
						exportChildren && exportChildren?.length > 0
							? exportChildren[0]?.id
							: item?.export?.id,
					id: item?.export?.id,
					price: item?.export?.price,
					valid: true,
				});
			});

		return state
			.set("exports", orderExports)
			.set("specification", lodSpecification?.id || null)
			.setIn(["validity", "specification"], true);
	};

	const setStateFromPackageConfiguration = (data) => {
		const {
			lodSpecification,
			exports,
			disciplineComponentCategoryLods,
			isDisciplineEqual,
		} = data;

		const tempData =
			disciplineComponentCategoryLods &&
			disciplineComponentCategoryLods.map((item) => {
				return {
					componentCategory: {
						id: item.componentCategoryId,
					},
					discipline: {
						id: item.disciplineId,
					},
					lod: {
						id: item.lodId,
						type: item.lodType,
					},
				};
			});

		return state
			.set("exports", exports)
			.set(
				"buildingDisciplineComponentCategoryLods",
				isDisciplineEqual ? null : tempData
			)
			.set(
				"specification",
				isDisciplineEqual
					? lodSpecification
					: `${lodSpecification}-draft` || null
			)
			.set(
				"configurationSpecification",
				isDisciplineEqual ? null : lodSpecification || null
			)
			.setIn(["validity", "specification"], true);
	};

	const updatePricePerSquarePercentage = (data) => {
		const { value } = data;
		const buildings = state.get("buildings");

		const tempBuildings = buildings.map((building) => {
			building.customPricePerSquare = getNewValueFromDiffPercentage(
				building.pricePerSquare,
				value
			)?.toFixed(2);

			building.customAdjustedPricePerSquare = getNewValueFromDiffPercentage(
				building.customPricePerSquare,
				building.customComplexityAdjustment
			)?.toFixed(3);
			building.price = +(
				building.customAdjustedPricePerSquare * +building.size
			)?.toFixed(2);

			return building;
		});

		return state
			.set("buildings", tempBuildings)
			.set("stopCalculatePercentage", true);
	};

	const updateExportPricePercentage = (data) => {
		const { value } = data;
		const exports = state.get("exports");

		const tempExports = exports.map((item) => {
			item.customPrice = +getNewValueFromDiffPercentage(
				item.price,
				value
			)?.toFixed(2);

			return item;
		});

		return state
			.set("exports", tempExports)
			.set("stopCalculateExportPercentage", true);
	};

	const setTempCompanyInfo = (data) => {
		const order = data;

		const companyInfo = {
			//offer
			name: order?.name || "",
			//client
			clientId: order?.clientCompany?.id || null,
			clientCP: order?.clientContactPerson?.id || null,
			clientLocation: null,
			clientPL: order?.clientProjectLead?.id || null,
			//reseller
			resellerCP: order?.resellerContactPerson?.id || null,
			resellerId: order?.resellerCompany?.id || null,
			resellerPL: order?.resellerProjectLead?.id || null,
			resellerLocation: null,
			//supplier
			supplierCP: order?.suplierContactPerson?.id || null,
			supplierId: order?.bimifyCompany?.id || null,
			supplierPL: order?.suplierProjectLead?.id || null,
			supplierLocation: null,
		};

		return state
			.set("tempCompanyInfo", fromJS(companyInfo))
			.set("companyInfo", fromJS(companyInfo));
	};

	switch (action.type) {
		case orderTempConstants.SET_STATE_FIELD_VALUE:
			return state
				.set("tempBuildingId", null)
				.set(
					"stopPricing",
					action.data.stateKey === "specification" ||
						action.data.stateKey === "numberOfBuildings"
						? false
						: true
				)
				.set(action.data.stateKey, action.data.value)
				.setIn(["validity", action.data.stateKey], action.data.isValid);

		case orderTempConstants.SET_COMPANY_STATE_FIELD_VALUE:
			return state.setIn(
				["companyInfo", action.data.stateKey],
				action.data.value
			);
		case orderTempConstants.CLEAR_COMPANY_STATE_VALUE:
			return state.set("companyInfo", state.get("tempCompanyInfo"));

		case orderTempConstants.SET_TEMP_COMPANY_INFO:
			return setTempCompanyInfo(action.data);

		case orderTempConstants.SET_TEMP_PROJECT:
			const order = action.data;

			const buildings = order.buildings;

			let orderExports = [];
			let tempBuildings = [];

			//Delivery Format
			let orderDeliveryFormat = order.orderDeliveryFormats[0].deliveryFormat.id;

			//Export format
			order.orderExports &&
				order.orderExports.map((orderExport) => {
					return orderExports.push({
						id: orderExport.export?.parent
							? orderExport.export?.parent.id
							: orderExport.export.id,
						exportId: orderExport.export.id,
						price: orderExport.price,
						customPrice: orderExport.customPrice,
						valid: true,
					});
				});

			//Buildings
			buildings &&
				buildings.map((building, index) => {
					return tempBuildings.push({
						id: index + 1,
						buildingId: building.id,
						name: building.name,
						size: building.size,
						price: building.price,
						systemAdjustedTotalPrice: Number(
							building.systemAdjustedTotalPrice
						)?.toFixed(2),
						pricePerSquare: building.pricePerSquare,
						customPricePerSquare: building.customPricePerSquare,
						systemComplexityAdjustmentPercentage:
							building.systemComplexityAdjustmentPercentage,
						complexityAdjustment: Number(
							building.systemComplexityAdjustmentPercentage * 100
						)?.toFixed(2),
						customComplexityAdjustment: Number(
							building.customComplexityAdjustmentPercentage * 100
						)?.toFixed(2),

						adjustedPricePerSquare: Number(
							building.systemAdjustedPricePerSquare
						)?.toFixed(3),
						customAdjustedPricePerSquare: Number(
							building.customSystemAdjustedPricePerSquare ||
								building.systemAdjustedPricePerSquare
						)?.toFixed(3),
						isCustomName: true,
						inputFormat: building?.inputFormat?.id,
						type: building.type.id,
						lodSpecification: building.lodSpecification?.id || null,
						levels: building?.levelsTotal || null,
						uniqueLevels: building?.uniqueLevels || null,
						validity: Map({
							size: true,
							inputFormat: true,
							type: true,
							levelsValidity: true,
						}),
					});
				});

			return state
				.set("additionalInfo", order.additionalInfo)
				.set("buildings", tempBuildings)
				.set("tempBuildingId", null)
				.set("specification", tempBuildings[0]?.lodSpecification || null)
				.set("clientCompany", order.clientCompany.id)
				.set("pricePerSquarePercentage", +order.pricePerSquarePercentage)
				.set("vatPercentage", order.vatPercentage || 0)
				.set("deliveryDays", order.deliveryDays || 0)
				.set("template", order.revitFile?.id || null)
				.set("name", order.name)
				.set("numberOfBuildings", tempBuildings.length)
				.set("discount", order.clientDiscount || 0)
				.set("exports", orderExports)
				.set("deliveryFormat", orderDeliveryFormat)
				.setIn(["validity", "deliveryFormat"], true)
				.setIn(["validity", "numberOfBuildings"], true)
				.setIn(["validity", "name"], true)
				.setIn(["validity", "specification"], true)
				.setIn(["validity", "template"], true)
				.setIn(
					["validity", "vatPercentage"],
					!!order.vatPercentage ? true : false
				)
				.setIn(
					["validity", "deliveryDays"],
					!!order.deliveryDays ? true : false
				);

		case orderTempConstants.UPDATE_BUILDING:
			return updateBuilding(action.data);

		case orderTempConstants.UPDATE_BUILDING_DATA:
			return updateBuildingData(action.data);

		case orderTempConstants.REMOVE_BUILDING:
			return removeBuilding(action.data);

		case orderTempConstants.UPDATE_PRICE:
			return updatePrice(action.data);

		case orderTempConstants.UPDATE_BUILDING_PRICE:
			return updateBuildingPrice(action.data);

		case orderTempConstants.SET_INIT_NUMBER_OF_BUILDINGS:
			return state
				.set(action.data.key, action.data.value)
				.setIn(["validity", action.data.key], true)
				.set("init", false);

		case orderTempConstants.SET_EXPORTS:
			return setExports(action.data);

		case orderTempConstants.SET_STATE_FROM_PACKAGE:
			return setStateFromPackage(action.data);

		case orderTempConstants.SET_STATE_FROM_PACKAGE_CONFIGURATION:
			return setStateFromPackageConfiguration(action.data);

		case orderTempConstants.SET_TEMP_TERMS_AND_CONDITIONS:
			return state.set("termsAndConditions", action.data);

		case orderTempConstants.SET_TEMP_ADDITIONAL_INFO:
			return state.set("additionalInfo", action.data);

		case orderTempConstants.CLEAR_TEMP_PROJECT:
			const { canSaveAdditionalInfo } = action.data;

			if (!!canSaveAdditionalInfo) {
				return initialState
					.set("additionalInfo", state.get("additionalInfo"))
					.set("termsAndConditions", state.get("termsAndConditions"))
					.set("companyInfo", state.get("companyInfo"))
					.set("tempCompanyInfo", state.get("tempCompanyInfo"));
			}

			return initialState;

		case orderTempConstants.UPDATE_PRICE_PER_SQUARE_PERCENTAGE:
			return updatePricePerSquarePercentage(action.data);

		case orderTempConstants.UPDATE_EXPORT_PRICE_PERCENTAGE:
			return updateExportPricePercentage(action.data);

		default:
			return state;
	}
};
