import React, { useState } from "react";
import "./componentTooltip.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ComponentTooltip = ({
	content,
	children,
	direction = "top",
	delColor = "",
	wrapperClasses = "",
}) => {
	const [active, setActive] = useState(false);

	const showTip = () => {
		setActive(true);
	};

	const hideTip = () => {
		setActive(false);
	};

	return (
		<div
			className={`tooltip-wrapper ${wrapperClasses}`}
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			{children}
			{active && (
				<div
					className={`tooltip-tip body-font body-font--small regular ${direction}`}
				>
					<ReactMarkdown
						components={{
							// Rewrite `del`s (`~~like so~~`) to `span` with a red foreground color.
							del(props) {
								const { node, ...rest } = props;

								return (
									<span
										className={delColor !== "color-n300" ? delColor : ""}
										{...rest}
									/>
								);
							},
						}}
						remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
						children={content?.replaceAll("\n", "  \n")}
					/>
				</div>
			)}
		</div>
	);
};

export default ComponentTooltip;
