import { getToken } from "../utils/local-storage.util";
import { api } from "../api";
import priceConstants from "../constants/priceConstants";
import { setBuildingPrice, setPrice } from "./orderTempActions";

/**
 * Retrieves price based on the given parameters.
 *
 * SUCESS: Populates 'price' state
 *
 * @param {object} data
 * @param {boolean} isEdit - true/false, true - update price 'offerTemp' object
 * @returns
 */
export const getPrice = ({
	data,
	isEdit = false,
	controller,
	cb = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/price",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
			signal: controller.signal,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: priceConstants.GET_PRICE,
					data: res.data.result,
				});
				console.log("is edit - - - - - - - - -", isEdit);
				if (isEdit) {
					dispatch(setPrice(res.data.result));
				} else {
					dispatch(setBuildingPrice(res.data.result));
				}
				cb();
			},
			(err) => {
				console.log({ err });
			}
		);
	};
};

/**
 * Clear pricing state
 */
export const clearPricing = () => {
	return (dispatch) => {
		dispatch({ type: priceConstants.CLEAR_PRICING });
	};
};

/**
 * Remove price per building
 * @param {*} buildingId - building unique identifier
 * @returns
 */
export const removePricePerBuilding = (buildingId) => {
	return (dispatch) => {
		dispatch({
			type: priceConstants.REMOVE_PRICE_PER_BUILDING,
			data: buildingId,
		});
	};
};

/**
 * Remove building total price
 */
export const removeBuildingTotalPrice = () => {
	return (dispatch) => {
		dispatch({
			type: priceConstants.REMOVE_BUILDING_TOTAL_PRICE,
		});
	};
};
