import React, { useCallback, useEffect, useMemo } from "react";
import { Field, reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import CustomAutocomplete from "../fields/CustomAutocomplete";
import { isBimify, isBimifyReseller } from "../../utils/permission/user";
import { getGlobalCompanies, setCustomer } from "../../actions";
import localize, { LocKeys } from "../../constants/localizations";
import { companyTypesConstants } from "../../constants/constants";

/**
 * Global company component for global customer selection
 * @param {*} props
 * @returns
 */
const GlobalCompany = (props) => {
	const {
		initialValues,
		customer,
		reseller,
		clientCompanies,
		resellerCompanies,
		//Actions
		getGlobalCompanies,
		setCustomer,
	} = props;

	useEffect(() => {
		getGlobalCompanies({
			type: companyTypesConstants.CLIENT,
			resellerCompanyId: reseller,
		});
	}, [reseller, getGlobalCompanies]);

	useEffect(() => {
		if (isBimifyReseller()) {
			getGlobalCompanies({ type: companyTypesConstants.RESELLER });
		}
	}, [getGlobalCompanies]);

	const setValue = useCallback(
		(value, isReseller = false) => {
			setCustomer(value, isReseller);
		},
		[setCustomer]
	);

	const setClientValueItem = useCallback(
		(item) => {
			if (item?.resellerCompanyId) setValue(item?.resellerCompanyId, true);
		},
		[setValue]
	);

	useEffect(() => {
		setValue(customer);
	}, [customer, setValue]);

	useEffect(() => {
		setValue(reseller, true);
	}, [reseller, setValue]);

	const resellerCompanyOptions = useMemo(() => {
		const options = [];
		if (resellerCompanies && resellerCompanies.size > 0) {
			resellerCompanies.map((company) => {
				const value = company.get("id");
				const label = company.get("name");

				return options.push({
					value,
					label,
				});
			});
		}
		return options;
	}, [resellerCompanies]);

	const clientCompanyOptions = useMemo(() => {
		const options = [];
		if (clientCompanies && clientCompanies.size > 0) {
			clientCompanies.map((company) => {
				const value = company.get("id");
				const label = company.get("name");

				return options.push({
					value,
					label,
					resellerCompanyId: company.get("resellerCompanyId"),
				});
			});
		}
		return options;
	}, [clientCompanies]);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			autoComplete="off"
			noValidate
		>
			<div className="row row--6p-gutters justify-content-end">
				{isBimifyReseller() && (
					<div className="col col-50">
						<Field
							name="reseller"
							id="reseller"
							disableClearable={!isBimify()}
							component={CustomAutocomplete}
							placeholder={localize(LocKeys.SELECT_RESELLER) + "..."}
							showPlaceholder={true}
							initialValue={initialValues?.get("reseller") || null}
							options={resellerCompanyOptions}
							customOnChange={(value) => {
								setValue(value, true);
								setValue(null);
							}}
							disabled={!isBimify()}
						/>
					</div>
				)}
				<div className={`col col-50`}>
					<Field
						name="customer"
						id="customer"
						disableClearable={!isBimifyReseller()}
						component={CustomAutocomplete}
						placeholder={localize(LocKeys.SELECT_CUSTOMER) + "..."}
						showPlaceholder={true}
						initialValue={initialValues?.get("customer") || null}
						options={clientCompanyOptions}
						customOnChange={(value) => setValue(value)}
						customOnChangeItem={(value) => setClientValueItem(value)}
						disabled={!isBimifyReseller()}
					/>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		clientCompanies: state.getIn(["company", "globalClientCompanies"]),
		resellerCompanies: state.getIn(["company", "globalResellerCompanies"]),
		customer: state.getIn(["global", "customer"]),
		reseller: state.getIn(["global", "reseller"]),

		initialValues: {
			customer: state.getIn(["global", "customer"]),
			reseller: state.getIn(["global", "reseller"]),
		},
	};
};

export default connect(mapStateToProps, {
	getGlobalCompanies,
	setCustomer,
})(
	reduxForm({
		form: "globalCompanyForm",
		destroyOnUnmount: true,
		touchOnBlur: false,
	})(GlobalCompany)
);
